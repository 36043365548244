<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Archived Articles</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Articles
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="articles"
        no-data-text="No Archived Articles found"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    parentRouteName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard" },
          exact: true,
        },
        {
          text: "News",
          disabled: false,
          to: { name: "module-paw-news" },
          exact: true,
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Author", value: "author_name" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    articles() {
      return this.$store.state.paw["archivedArticles"];
    },
  },
};
</script>
